#upload-slide-wrapper {
    position: relative;
}

.upload-slide {
    display: none;
    width: 750px;
    padding: 20px 60px;
    height: 250px;
    margin-bottom: 50px;
    border-top: 1px solid rgb(230,230,230);
    border-bottom: 1px solid rgb(230,230,230);
    align-items: center;
}

.upload-slide p {
    font-size: 11pt;
    line-height: 1.2;
    text-align: left;
}

#upload-next-btn {
    position: absolute;
    right: 0px;
    top: 40%;
}

#upload-prev-btn {
    position: absolute;
    left: 0px;
    top: 40%;
}

.upload-slide img {
    height: 100%;
    margin-left: 20px;
    border: 1px solid rgb(220,220,220);
}



.slide-button2 {
    padding: 10px 17px;
    border-radius: 4px;
    background-color: rgb(138 138 138);
    opacity: 0.3;
    transition: 0.5s;
    font-size: 20pt;
}

#upload-slide-wrapper:hover .slide-button2 {
    opacity: 0.5;
    transition: 0.5s;   
}


#upload-progress-indicators {
      position: absolute;
      bottom: -25px;
      right: 0;
      left: 0;
      display: block;
}

  #upload-progress-indicators > div {
      height: 8px;
      width: 8px;
      margin: auto 5px 5px 5px;
      border-radius: 10px;
      border: 1px solid black;
      display: inline-block;
  }

  @media (max-width: 1060px) {
    .upload-slide img {
        width: 100%;
        max-width: 500px;
        height: auto;
        margin-left: 0px;
    }
    .upload-slide {
        flex-direction: column;
        justify-content: space-between;
        width: 450px;
        height: 420px;
    }
  }

