#bd-preview-wrapper {
  flex-grow: 1;
  margin-left: 10px;
  height: 100%;
  position: relative;
  overflow: hidden;
}

#bd-preview {
overflow: auto;
height: 100%;
padding-right: 30px;
}

#title-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#title-flex > * {
  flex-grow: 1;
}

#month-selector {
  text-align: center;
}

#month-display {
  display: inline-block;
  width: 120px;
}

#month-selector {
  margin-left: 85px;
}

.name-unit {
  display: block;
  line-height: 1;
  margin-bottom: 0.5em;
  cursor: default;
  position: relative;
}

.name-unit:hover::after {
  content: "Double click to change";
  position: absolute;
  font-size: 11pt !important;
  font-family: "Raleway", sans-serif;
  top: 20px;
  left: 25px;
  background-color: rgb(206, 206, 206);
  border-radius: 3px;
  padding: 3px;
  z-index: 99;
}

#ring-image {
  position: absolute;
  top: -74px;
  left: 10%;
  width: 80%;
  z-index: 3;
}

#button-flex {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  margin-top: 10px;
  margin-right: 10px;
}

#button-flex > * {
  flex-grow: 1;
}

/* #cutout {
  width: 85px;
  height: 54px;
  background-color: #c7c7c7;
  position: absolute;
  top: -5px;
  left: 44.6%;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  box-shadow: inset 0px -20px 12px 0px grey;
} */

.slide-button {
  position: absolute;
  height: 100px;
  top: 40px;
  width: 100px;
  border-radius: 10px;
  background-color: rgb(138 138 138);
  opacity: 0.3;
  transition: 0.5s;
  font-size: 40pt;
  z-index: 10;
}

#back-button {
  left: 0px;
}

#forward-button {
  right: 40px;
}

#bd-preview:hover .slide-button, #bd-preview:hover #zoom-buttons {
  opacity: 0.5;
  transition: 0.5s;
}

#zoom-buttons {
  position: absolute;
  z-index: 99;
  bottom: 10px;
  right: 10px;
  width: 70px;
  opacity: 0.3;
  transition: 0.5s;
}

#zoom-buttons button:disabled {
  opacity: 0.4;
}

#zoom-buttons img {
  width: 100%;
}

#zoom-buttons * {
  background: none;
}
