
/* This print.css file is used to create a pdf of the calender that is created in the tool. This file should only be imported in the pdfendpoint file */

body {
  -webkit-print-color-adjust: exact;
}

#pdf-endpoint * {
  visibility: hidden;
}
#pdf-endpoint #section-to-print,
#pdf-endpoint #section-to-print * {
  visibility: visible;
}
#pdf-endpoint #section-to-print {
  position: absolute;
  left: 0;
  top: 0;
  padding-bottom: 0px !important;
}
#pdf-endpoint .page-wrapper {
  display: block !important;
}

/* Getting rid of footer and header */
@page {
  margin: 0;
}

#pdf-endpoint .page-wrapper {
  page-break-after: always;
}

/* Getting rid of extra page in print */

#pdf-endpoint #section-to-print > div:last-child {
  height: 296mm !important;
}

/* Fixing relative-absolute problem */
#pdf-endpoint #bd-preview-wrapper {
  position: initial !important;
}

/* Fixing other weird header problem */
#pdf-endpoint #header {
  display: none !important;
}

/* Cleaning up */

#pdf-endpoint .page-wrapper {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

#pdf-endpoint #calender-preview {
  transform: none !important;
  position: unset !important;
}




