.page-wrapper {
  display: flex;
  width: 210mm;
  height: 297mm;
  padding: 50px 35px 15px 35px;
  box-sizing: border-box;
  /* background-image: url("../BdPreview/img/A4_background.png"); */
  background-color: white;
  background-size: 100% 100%;
  -webkit-box-shadow: 0px 11px 19px 5px rgba(0, 0, 0, 0.69);
  box-shadow: 0px 11px 19px 5px rgba(0, 0, 0, 0.69);
}

#calender-preview {
  transform-origin: center top;
  position: relative;
  margin: 55px auto 0 auto;
  width: 210mm;
  height: 0mm;

}

#calender-preview.zoom_1 {
  transform: scale(1.4);
  transform-origin: center top;
}

#calender-preview.zoom_2 {
  transform: scale(1.2);
  transform-origin: center top;
}

#calender-preview.zoom_3 {
  transform: scale(1);
  transform-origin: center top;
}

#calender-preview.zoom_4 {
  transform: scale(0.8);
  transform-origin: center top;
}

#calender-preview.zoom_5 {
  transform: scale(0.6);
  transform-origin: center top;
}
#calender-preview.zoom_6 {
  transform: scale(0.4);
  transform-origin: center top;
}

#section-to-print {
  padding-bottom: 50px;

}

