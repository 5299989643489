/* ############# Fonts ################## */

@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap");

.font_1 .day-names {
  font-family: "Raleway", sans-serif;
}

.font_2 .day-names {
  font-family: "Roboto", sans-serif;
}

.font_3 .day-names {
  font-family: "Dancing Script", cursive;
}

.font_4 .day-names {
  font-family: "Times New Roman", Times, serif;
}

.font_5 .day-names {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.font_6 .day-names {
  font-family: "Ubuntu", sans-serif;
}

.font_7 .day-names {
  font-family: "Times New Roman", Times, serif;
}

.fontsize_1 .day-names {
  font-size: 6pt;
}

.fontsize_2 .day-names {
  font-size: 7pt;
}

.fontsize_3 .day-names {
  font-size: 8pt;
}

.fontsize_4 .day-names {
  font-size: 9pt;
}

.fontsize_5 .day-names {
  font-size: 10pt;
}

.fontsize_6 .day-names {
  font-size: 11pt;
}

.fontsize_7 .day-names {
  font-size: 12pt;
}
.fontsize_8 .day-names {
  font-size: 13pt;
}
.fontsize_9 .day-names {
  font-size: 14pt;
}

.page-wrapper {
  background-repeat: no-repeat;
}

.bg_2 .page-wrapper {
  background-image: none;
}

.bg_2 .page-wrapper {
  background-size: 90% auto;
  background-position: center 200px;
  background-image: url('../BdPreview/img/ballon_bg.png');
}

.bg_3 .page-wrapper {
  background-size: 100% auto;
  background-position: center -40px;
  background-image: url('../BdPreview/img/flag_bg.png');
}

/* ############# Template 1 ############# */
@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap");
.temp_1 .page-wrapper {
  /* background-color: white; */
  flex-direction: column;
}

.temp_1 {
  color: rgb(54, 54, 54);
}

.temp_1 .month-title > h2 {
  font-size: 40pt;
  text-align: center;
  font-family: "Great Vibes", cursive;
  margin: 20px auto;
}

.temp_1 .day-tiles {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-right: -20px;
}

.temp_1 .day-block {
  position: relative;
  height: 127px;
  width: 129.5px;
  padding: 3px 7px 0px 7px;
  font-size: 13pt;
  overflow: hidden;
  /* Border logic */
  border-width: 0px;
  border-color: rgb(73, 73, 73);
  border-style: solid;
  border-left-width: 1px;
  border-bottom-width: 1px;
}

.temp_1 .day-block:nth-child(-n + 5) {
  border-top-width: 1px;
}

.temp_1 .day-block:nth-child(5n) {
  border-right-width: 1px;
}

.temp_1 .day-block:last-child {
  border-right-width: 1px;
}

.temp_1 .day-names {
  margin-top: 0.7em;
  line-height: 1.3;
}

.temp_1 .day-number {
  float: right;
  font-size: 20pt;
  font-family: "Great Vibes", cursive;
}

/* ############# Template 2 ############# */

.temp_2 .page-wrapper {
  /* background-color: white; */
  flex-direction: column;
  padding-bottom: 20px;
}

.temp_2 .month-title > h2 {
  font-size: 35pt;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  text-align: center;
}

.temp_2 .day-tiles {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  max-height: 260mm;
}

.temp_2 .day-block {
  margin-left: 4px;
  position: relative;
  height: 47px;
  width: 47%;
  border-bottom: 2px solid rgb(70, 70, 70);
  margin-bottom: 7px;
  padding: 3px 7px 0px 0px;
  font-size: 13pt;
  overflow: hidden;
}

.temp_2 .day-names {
  margin-top: 0px;
  line-height: 1.8;
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 49px;
}

.temp_2 .day-number {
  float: left;
  font-size: 20pt;
  margin-top: 4px;
}

/* ############# Template 3 ############# */
.temp_3 .page-wrapper {
  /* background-color: rgb(255, 255, 255);
  flex-direction: column; */
  position: relative;
  padding-right: 0;
}

.temp_3 .month-title {
  position: absolute;
  bottom: 30px;
  right: 55px;
}

.temp_3 .month-title > h2 {
  font-size: 70pt;
}

.temp_3 .day-tiles {
  margin-top: 40px;
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.temp_3 .day-tiles > div:nth-child(even) {
  background-color: rgb(230, 230, 230);
}

.temp_3 .day-tiles > div:nth-child(odd) {
  background-color: rgb(255, 255, 255);
}

.temp_3 .day-tiles > div:nth-child(odd) .day-number {
  color: rgb(230, 230, 230);
}

.temp_3 .day-block {
  position: relative;
  display: inline-block;
  height: 110px;
  width: 110px;
  border: 2px solid black;
  margin-right: 15px;
  margin-bottom: 15px;
  padding: 7px;
  font-size: 13pt;
  overflow: hidden;
  z-index: 0;
}

.temp_3 .day-names {
  margin-top: 4px;
  line-height: 1.8;
  z-index: 2;
}

.temp_3 .day-number {
  position: absolute;
  text-align: center;
  font-weight: bold;
  right: 0;
  left: 0;
  top: 6px;
  margin: auto;
  font-size: 70pt;
  opacity: 1;
  color: white;
  z-index: -1;
}

/* ############# Template 4 ############# */
.temp_4 {
  color: rgb(54, 54, 54);
}

.temp_4 .page-wrapper {
  position: relative;
}

.temp_4 .month-title > h2 {
  position: absolute;
  bottom: 30px;
  right: 45px;
  font-size: 50pt;
  font-family: "Great Vibes", cursive;
}

.temp_4 .day-tiles {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-right: -20px;
}

.temp_4 .day-block {
  position: relative;
  height: 143px;
  width: 129.5px;
  padding: 3px 7px 0px 7px;
  font-size: 13pt;
  overflow: hidden;
  /* Border logic */
  border-width: 0px;
  border-color: rgb(73, 73, 73);
  border-style: solid;
  border-left-width: 1px;
  border-bottom-width: 1px;
}

.temp_4 .day-block:nth-child(-n + 5) {
  border-top-width: 1px;
}

.temp_4 .day-block:nth-child(5n) {
  border-right-width: 1px;
}

.temp_4 .day-block:last-child {
  border-right-width: 1px;
}

.temp_4 .day-names {
  margin-top: 0.7em;
  line-height: 1.3;
}

.temp_4 .day-number {
  float: right;
  font-size: 20pt;
  font-family: "Great Vibes", cursive;
}