#new-birthday {
  border-radius: 7px;
  background-color: white;
  padding: 15px;
}

#new-birthday input[type="number"] {
  width: 35px;
  height: 21px;
}

#new-birthday input[type="text"] {
  width: 140px;
}

#new-birthday select {
  width: 95px;
}

#first-row-new-birthday {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#add-new-button {
  width: 100%;
  margin-top: 5px;
  font-size: 12pt;
}
