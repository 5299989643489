#header {
  display: flex;
  flex-direction: row;
  top: 0;
  left: 0;
  height: 60px;
  margin-bottom: 5px;
  background-color: white;
  box-shadow: 0 0 12px 0px rgb(0 0 0 / 100%);
  padding: 5px 10px;
  box-sizing: border-box;

}

#header > div:first-child {
  display: flex;
  flex: 0 0 350px;
  flex-direction: column;
  height: 100%;
  flex-basis: 365px;
}

#header > div:nth-child(2) {
  flex-grow: 1;
} 

#pdf-print-button {
  float: right;
  height: 100%;
  padding: 0 30px;
}

#preview-controls select {
  font-size: 11pt;
  height: 22px;
  background-color: rgb(231 231 231);
  border-radius: 5px;
  border: none;
  font-size: 15px;
  height: 25px;
  border: 1px solid grey;
}

#preview-controls select:hover {
  background-color: rgb(245, 245, 245);
}

#preview-controls select:not(:nth-of-type(1)) {
  margin-left: 5px;
}


#preview-controls {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  height: 100%;
}

#preview-controls > div {
  padding: 4px 20px 0px 20px;
  margin-bottom: -10px;
  border-left: 1.5px solid rgb(219, 219, 219);
  line-height: 1.2;
  height: 90%;
}

.option-title {
  font-size: 11pt;
  width: 80px;
}

@media (max-width: 1260px) {
  #preview-controls select {
    font-size: 11pt;
    height: 22px;
    max-width: 110px;
  }

  .option-title {
    display: none;
  }

  #preview-controls select:not(:nth-of-type(1)) {
    margin-left: 0px;
  }

  #preview-controls select:nth-of-type(1) {
      margin-right: 5px;
}
}

@media (max-width: 1260px) {
  #preview-controls > div {
    padding: 4px 10px 0px 10px;
}
}