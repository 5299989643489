@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

#root {
  --offblack: rgb(39, 39, 39);
  --offwhite: rgb(202, 202, 202);
}

body {
  background-color: #f0f0f0;
  background-image: url("./components/img/white-background.jpg");
  background-size: 100%;
  background-position: center;
  min-width: 1000px;
}

body,
button,
select,
input {
  font-family: "Raleway", sans-serif;
  color: var(--offblack);
}

body {
  font-size: 14pt;
  margin: 0;
  padding: 0;
}

.bold {
  font-weight: bold;
}

.centered-text {
  text-align: center;
}

h2 {
  font-size: 20px;
  margin: 5px auto 15px auto;
}

.small-title {
  font-size: 12pt;
  font-weight: bold;
}
