/* CSS */
.button-62 {
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.8;
  outline: transparent;
  text-align: center;
  text-decoration: none;
  transition: box-shadow 0.2s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  border-radius: 3px;
	color: #fff;
	background: #027ad6;
	box-shadow:
		0 1px 2px rgba(0,0,0,0.07), 
		0 2px 4px rgba(0,0,0,0.07), 
		0 4px 8px rgba(0,0,0,0.07), 
		0 8px 16px rgba(0,0,0,0.07),
		0 16px 32px rgba(0,0,0,0.07), 
		0 32px 64px rgba(0,0,0,0.07);
}

.button-62.bigger {
  background: #027ad6;
	box-shadow:
		0 1px 2px rgba(0,0,0,0.07), 
		0 2px 4px rgba(0,0,0,0.07), 
		0 4px 8px rgba(0,0,0,0.07), 
		0 8px 16px rgba(0,0,0,0.07),
		0 16px 32px rgba(0,0,0,0.07), 
		0 32px 64px rgba(0,0,0,0.07);
  color: white;
  font-weight: 500;
}

.button-62:disabled {
  opacity: 0.6;
}

.button-62:not([disabled]):focus {
  box-shadow:
		0 1px 2px rgba(0,0,0,0.17), 
		0 2px 4px rgba(0,0,0,0.17), 
		0 4px 8px rgba(0,0,0,0.17), 
		0 8px 16px rgba(0,0,0,0.17),
		0 16px 32px rgba(0,0,0,0.17), 
		0 32px 64px rgba(0,0,0,0.17);
}

.button-62:not([disabled]):hover {
  box-shadow:
		0 1px 2px rgba(0,0,0,0.17), 
		0 2px 4px rgba(0,0,0,0.17), 
		0 4px 8px rgba(0,0,0,0.17), 
		0 8px 16px rgba(0,0,0,0.17),
		0 16px 32px rgba(0,0,0,0.17), 
		0 32px 64px rgba(0,0,0,0.17);
}

.fa-big-button {
  font-size: 1.5em;
  margin-right: 10px;
}
