#import-box {
  text-align: center;
  margin-bottom: 10px;
}

input[type="file"] {
  display: none;
}

#upload-fb-button {
  float: left;
  width: 335px;
  height: 100%;
  line-height: 3.5;
}

#upload-button {
  padding: 14px 40px;
}

#upload-button * {
  cursor: pointer;
}

.fa-custom {
  font-size: 25px;
  display: inline-block;
}

#facebook-upload-popup {
  /* max-width: 500px; */
  min-width: 200px;
  border-radius: 5px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  background-color: white;
  padding: 15px 30px 30px 30px;
  -webkit-box-shadow: 0px 0px 19px 5px rgba(0, 0, 0, 0.69);
  box-shadow: 0px 0px 19px 5px rgba(0, 0, 0, 0.69);
}

.popup-wrapper {
  display: none;
}

.popup-background {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}

.close-popup {
  border-radius: 15px;
  float: right;
  line-height: 25px;
  width: 25px;
  margin-right: -25px;
  margin-top: -8px;
  box-shadow: none;
  height: 25px;
  transition: 0.1s;    
  text-align: center;
}

.close-popup:hover {
  box-shadow: 0px 0px 4px 1px rgb(0 0 0 / 69%);
  cursor: pointer;
  transition: 0.1s;
}
