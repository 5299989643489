#page-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  height: calc(100vh - 65px);
}

#side-menu {
  display: flex;
  flex: 0 0 350px;
  flex-direction: column;
  height: 100%;
  flex-basis: 365px;
  overflow: hidden;

}

#side-menu > div:first-child {
  margin-top: 10px;
}

#side-menu > div {
  margin: auto 20px 10px 10px;
}

#side-menu > div {
  box-shadow: 0 0 12px 0px rgb(0 0 0 / 100%);
}

/* Headers */

.num-circle {
  display: inline-block;
  background: linear-gradient(to bottom right, #5289ff, #32649c);
  width: 40px;
  height: 38px;
  line-height: 1;
  text-align: center;
  font-size: 25pt;
  margin: 2px auto 2px 2px;
}

.h2-title {
  display: inline-block;
  vertical-align: middle;
  margin-left: 15px;
  font-weight: 200;
}

.step-title {
  background-color: rgb(178 196 223);
  height: 42px;
  text-align: left;
}

/* Buttons */

.large-button {
  color: var(--offblack);
  font-size: 14pt;
  padding: 10px 20px;
  border-radius: 3px;
  background-color: rgb(211, 214, 255);
  font-weight: bold;
  border: 2px solid blue;
  margin: 10px 10px;
}

.large-button:hover {
  border-color: white;
  background-color: rgb(129, 129, 248);
  color: white;
  cursor: pointer;
}

/* Spacing */

.small-margin {
  margin-top: 2px;
  margin-bottom: 2px;
}

.smalltext {
  font-size: 9pt;
}
