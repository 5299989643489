button:focus {
  outline: none;
}

button:hover {
  cursor: pointer;
}

button:disabled:hover {
  cursor: default;
}

button {
  border: none;
}
