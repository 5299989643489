.scrollbar-1 {
    overflow: auto;
}

.scrollbar-1::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.scrollbar-1::-webkit-scrollbar
{
	width: 12px;
	background-color: rgba(0,0,0,0);
}

.scrollbar-1::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: rgb(158, 158, 158);
    min-height: 40px;
}

.scrollbar
{
	margin-left: 30px;
	float: left;
	height: 300px;   
	width: 65px;
	background: #F5F5F5;
	overflow-y: scroll;
	margin-bottom: 25px;
}
