@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap);
#root {
  --offblack: rgb(39, 39, 39);
  --offwhite: rgb(202, 202, 202);
}

body {
  background-color: #f0f0f0;
  background-image: url(../../static/media/white-background.6d3d542b.jpg);
  background-size: 100%;
  background-position: center;
  min-width: 1000px;
}

body,
button,
select,
input {
  font-family: "Raleway", sans-serif;
  color: var(--offblack);
}

body {
  font-size: 14pt;
  margin: 0;
  padding: 0;
}

.bold {
  font-weight: bold;
}

.centered-text {
  text-align: center;
}

h2 {
  font-size: 20px;
  margin: 5px auto 15px auto;
}

.small-title {
  font-size: 12pt;
  font-weight: bold;
}

#page-wrapper {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: calc(100vh - 65px);
}

#side-menu {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 0 0 350px;
          flex: 0 0 350px;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
  -webkit-flex-basis: 365px;
          flex-basis: 365px;
  overflow: hidden;

}

#side-menu > div:first-child {
  margin-top: 10px;
}

#side-menu > div {
  margin: auto 20px 10px 10px;
}

#side-menu > div {
  box-shadow: 0 0 12px 0px rgb(0 0 0 / 100%);
}

/* Headers */

.num-circle {
  display: inline-block;
  background: linear-gradient(to bottom right, #5289ff, #32649c);
  width: 40px;
  height: 38px;
  line-height: 1;
  text-align: center;
  font-size: 25pt;
  margin: 2px auto 2px 2px;
}

.h2-title {
  display: inline-block;
  vertical-align: middle;
  margin-left: 15px;
  font-weight: 200;
}

.step-title {
  background-color: rgb(178 196 223);
  height: 42px;
  text-align: left;
}

/* Buttons */

.large-button {
  color: var(--offblack);
  font-size: 14pt;
  padding: 10px 20px;
  border-radius: 3px;
  background-color: rgb(211, 214, 255);
  font-weight: bold;
  border: 2px solid blue;
  margin: 10px 10px;
}

.large-button:hover {
  border-color: white;
  background-color: rgb(129, 129, 248);
  color: white;
  cursor: pointer;
}

/* Spacing */

.small-margin {
  margin-top: 2px;
  margin-bottom: 2px;
}

.smalltext {
  font-size: 9pt;
}

button:focus {
  outline: none;
}

button:hover {
  cursor: pointer;
}

button:disabled:hover {
  cursor: default;
}

button {
  border: none;
}

/* CSS */
.button-62 {
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.8;
  outline: transparent;
  text-align: center;
  text-decoration: none;
  transition: box-shadow 0.2s ease-in-out;
  -ms-user-select: none;
      user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  border-radius: 3px;
	color: #fff;
	background: #027ad6;
	box-shadow:
		0 1px 2px rgba(0,0,0,0.07), 
		0 2px 4px rgba(0,0,0,0.07), 
		0 4px 8px rgba(0,0,0,0.07), 
		0 8px 16px rgba(0,0,0,0.07),
		0 16px 32px rgba(0,0,0,0.07), 
		0 32px 64px rgba(0,0,0,0.07);
}

.button-62.bigger {
  background: #027ad6;
	box-shadow:
		0 1px 2px rgba(0,0,0,0.07), 
		0 2px 4px rgba(0,0,0,0.07), 
		0 4px 8px rgba(0,0,0,0.07), 
		0 8px 16px rgba(0,0,0,0.07),
		0 16px 32px rgba(0,0,0,0.07), 
		0 32px 64px rgba(0,0,0,0.07);
  color: white;
  font-weight: 500;
}

.button-62:disabled {
  opacity: 0.6;
}

.button-62:not([disabled]):focus {
  box-shadow:
		0 1px 2px rgba(0,0,0,0.17), 
		0 2px 4px rgba(0,0,0,0.17), 
		0 4px 8px rgba(0,0,0,0.17), 
		0 8px 16px rgba(0,0,0,0.17),
		0 16px 32px rgba(0,0,0,0.17), 
		0 32px 64px rgba(0,0,0,0.17);
}

.button-62:not([disabled]):hover {
  box-shadow:
		0 1px 2px rgba(0,0,0,0.17), 
		0 2px 4px rgba(0,0,0,0.17), 
		0 4px 8px rgba(0,0,0,0.17), 
		0 8px 16px rgba(0,0,0,0.17),
		0 16px 32px rgba(0,0,0,0.17), 
		0 32px 64px rgba(0,0,0,0.17);
}

.fa-big-button {
  font-size: 1.5em;
  margin-right: 10px;
}

.scrollbar-1 {
    overflow: auto;
}

.scrollbar-1::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.scrollbar-1::-webkit-scrollbar
{
	width: 12px;
	background-color: rgba(0,0,0,0);
}

.scrollbar-1::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: rgb(158, 158, 158);
    min-height: 40px;
}

.scrollbar
{
	margin-left: 30px;
	float: left;
	height: 300px;   
	width: 65px;
	background: #F5F5F5;
	overflow-y: scroll;
	margin-bottom: 25px;
}

#new-birthday {
  border-radius: 7px;
  background-color: white;
  padding: 15px;
}

#new-birthday input[type="number"] {
  width: 35px;
  height: 21px;
}

#new-birthday input[type="text"] {
  width: 140px;
}

#new-birthday select {
  width: 95px;
}

#first-row-new-birthday {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

#add-new-button {
  width: 100%;
  margin-top: 5px;
  font-size: 12pt;
}

#bdlist-UI {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  /* height: 100%; */
  overflow: hidden;
  margin-bottom: 10px;
  background-color: white;
  padding: 15px;
  border-radius: 7px;
}

#bd-list-container {
  font-size: 13px;
  overflow-y: auto;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  margin-left: -8px;
}

#bd-list-container > .list-item-birthday {
  padding: 3px 0 2px 0;
  border-bottom: 1px solid #f1f1f1;
}

#no-birthdays {
  padding: 15px;
  text-align: center;
}

.list-item-birthday {
  position: relative;
}

.list-item-birthday input:first-child {
  margin: 0px 4px 0px 6px;
  vertical-align: middle;
  display: inline-block;
}

#side-menu .list-item-birthday input:nth-child(2) {
  padding: 0px 3px;
  font-size: 13px;
  width: 130px;
}

.list-date {
  display: inline-block;
}

.list-remove-button {
  background-color: rgb(217 217 217);
  position: absolute;
  top: 0px;
  margin: 5px 5px auto auto;
  right: 0;
  padding: 2px 4px 1px 4px;
  color: white;
  font-weight: bold;
  border-radius: 3px;
}

.list-remove-button:hover {
  background-color: rgb(255, 215, 215);
}

#bdlist-option-button-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-content: stretch;
          align-content: stretch;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  grid-gap: 5px;
  gap: 5px;
}

#bdlist-option-button-wrapper > * {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  margin: auto;
}

.bdlist-option-button {
  border-radius: 3px;
}

#search-wrapper {
  position: relative;
  margin: 10px 0;
}

#side-menu input,
#side-menu select {
  background-color: rgb(231 231 231);
  border-radius: 5px;
  border: none;
  font-size: 15px;
  height: 25px;
  border: 1px solid grey;
}

#side-menu input[type="text"] {
  padding: 0px 10px;
}

#side-menu input:hover,
#side-menu select:hover {
  background-color: rgb(245, 245, 245);
}

#search-box {
  width: 93.5%;
}

#x-icon {
  position: absolute;
  right: 8px;
  top: 1px;
  margin-left: -15px;
  opacity: 0.5;
  font-weight: bold;
}

#x-icon:hover {
  opacity: 0.8;
  cursor: pointer;
}

.fa-wrapper {
  display: inline-block;
  width: 23px;
}

.facebook-icon {
  height: 1.5em;
  width: auto !important;
  vertical-align: middle;
  color: #457ad5;
  cursor: pointer;
}

#bd-preview-wrapper {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  margin-left: 10px;
  height: 100%;
  position: relative;
  overflow: hidden;
}

#bd-preview {
overflow: auto;
height: 100%;
padding-right: 30px;
}

#title-flex {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

#title-flex > * {
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

#month-selector {
  text-align: center;
}

#month-display {
  display: inline-block;
  width: 120px;
}

#month-selector {
  margin-left: 85px;
}

.name-unit {
  display: block;
  line-height: 1;
  margin-bottom: 0.5em;
  cursor: default;
  position: relative;
}

.name-unit:hover::after {
  content: "Double click to change";
  position: absolute;
  font-size: 11pt !important;
  font-family: "Raleway", sans-serif;
  top: 20px;
  left: 25px;
  background-color: rgb(206, 206, 206);
  border-radius: 3px;
  padding: 3px;
  z-index: 99;
}

#ring-image {
  position: absolute;
  top: -74px;
  left: 10%;
  width: 80%;
  z-index: 3;
}

#button-flex {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 10px;
  margin-right: 10px;
}

#button-flex > * {
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

/* #cutout {
  width: 85px;
  height: 54px;
  background-color: #c7c7c7;
  position: absolute;
  top: -5px;
  left: 44.6%;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  box-shadow: inset 0px -20px 12px 0px grey;
} */

.slide-button {
  position: absolute;
  height: 100px;
  top: 40px;
  width: 100px;
  border-radius: 10px;
  background-color: rgb(138 138 138);
  opacity: 0.3;
  transition: 0.5s;
  font-size: 40pt;
  z-index: 10;
}

#back-button {
  left: 0px;
}

#forward-button {
  right: 40px;
}

#bd-preview:hover .slide-button, #bd-preview:hover #zoom-buttons {
  opacity: 0.5;
  transition: 0.5s;
}

#zoom-buttons {
  position: absolute;
  z-index: 99;
  bottom: 10px;
  right: 10px;
  width: 70px;
  opacity: 0.3;
  transition: 0.5s;
}

#zoom-buttons button:disabled {
  opacity: 0.4;
}

#zoom-buttons img {
  width: 100%;
}

#zoom-buttons * {
  background: none;
}

.page-wrapper {
  display: -webkit-flex;
  display: flex;
  width: 210mm;
  height: 297mm;
  padding: 50px 35px 15px 35px;
  box-sizing: border-box;
  /* background-image: url("../BdPreview/img/A4_background.png"); */
  background-color: white;
  background-size: 100% 100%;
  box-shadow: 0px 11px 19px 5px rgba(0, 0, 0, 0.69);
}

#calender-preview {
  -webkit-transform-origin: center top;
          transform-origin: center top;
  position: relative;
  margin: 55px auto 0 auto;
  width: 210mm;
  height: 0mm;

}

#calender-preview.zoom_1 {
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
  -webkit-transform-origin: center top;
          transform-origin: center top;
}

#calender-preview.zoom_2 {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  -webkit-transform-origin: center top;
          transform-origin: center top;
}

#calender-preview.zoom_3 {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transform-origin: center top;
          transform-origin: center top;
}

#calender-preview.zoom_4 {
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  -webkit-transform-origin: center top;
          transform-origin: center top;
}

#calender-preview.zoom_5 {
  -webkit-transform: scale(0.6);
          transform: scale(0.6);
  -webkit-transform-origin: center top;
          transform-origin: center top;
}
#calender-preview.zoom_6 {
  -webkit-transform: scale(0.4);
          transform: scale(0.4);
  -webkit-transform-origin: center top;
          transform-origin: center top;
}

#section-to-print {
  padding-bottom: 50px;

}


/* ############# Fonts ################## */

.font_1 .day-names {
  font-family: "Raleway", sans-serif;
}

.font_2 .day-names {
  font-family: "Roboto", sans-serif;
}

.font_3 .day-names {
  font-family: "Dancing Script", cursive;
}

.font_4 .day-names {
  font-family: "Times New Roman", Times, serif;
}

.font_5 .day-names {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.font_6 .day-names {
  font-family: "Ubuntu", sans-serif;
}

.font_7 .day-names {
  font-family: "Times New Roman", Times, serif;
}

.fontsize_1 .day-names {
  font-size: 6pt;
}

.fontsize_2 .day-names {
  font-size: 7pt;
}

.fontsize_3 .day-names {
  font-size: 8pt;
}

.fontsize_4 .day-names {
  font-size: 9pt;
}

.fontsize_5 .day-names {
  font-size: 10pt;
}

.fontsize_6 .day-names {
  font-size: 11pt;
}

.fontsize_7 .day-names {
  font-size: 12pt;
}
.fontsize_8 .day-names {
  font-size: 13pt;
}
.fontsize_9 .day-names {
  font-size: 14pt;
}

.page-wrapper {
  background-repeat: no-repeat;
}

.bg_2 .page-wrapper {
  background-image: none;
}

.bg_2 .page-wrapper {
  background-size: 90% auto;
  background-position: center 200px;
  background-image: url(../../static/media/ballon_bg.fd7f90e9.png);
}

.bg_3 .page-wrapper {
  background-size: 100% auto;
  background-position: center -40px;
  background-image: url(../../static/media/flag_bg.f8bc4379.png);
}

/* ############# Template 1 ############# */
.temp_1 .page-wrapper {
  /* background-color: white; */
  -webkit-flex-direction: column;
          flex-direction: column;
}

.temp_1 {
  color: rgb(54, 54, 54);
}

.temp_1 .month-title > h2 {
  font-size: 40pt;
  text-align: center;
  font-family: "Great Vibes", cursive;
  margin: 20px auto;
}

.temp_1 .day-tiles {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  margin-right: -20px;
}

.temp_1 .day-block {
  position: relative;
  height: 127px;
  width: 129.5px;
  padding: 3px 7px 0px 7px;
  font-size: 13pt;
  overflow: hidden;
  /* Border logic */
  border-width: 0px;
  border-color: rgb(73, 73, 73);
  border-style: solid;
  border-left-width: 1px;
  border-bottom-width: 1px;
}

.temp_1 .day-block:nth-child(-n + 5) {
  border-top-width: 1px;
}

.temp_1 .day-block:nth-child(5n) {
  border-right-width: 1px;
}

.temp_1 .day-block:last-child {
  border-right-width: 1px;
}

.temp_1 .day-names {
  margin-top: 0.7em;
  line-height: 1.3;
}

.temp_1 .day-number {
  float: right;
  font-size: 20pt;
  font-family: "Great Vibes", cursive;
}

/* ############# Template 2 ############# */

.temp_2 .page-wrapper {
  /* background-color: white; */
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-bottom: 20px;
}

.temp_2 .month-title > h2 {
  font-size: 35pt;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  text-align: center;
}

.temp_2 .day-tiles {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  max-height: 260mm;
}

.temp_2 .day-block {
  margin-left: 4px;
  position: relative;
  height: 47px;
  width: 47%;
  border-bottom: 2px solid rgb(70, 70, 70);
  margin-bottom: 7px;
  padding: 3px 7px 0px 0px;
  font-size: 13pt;
  overflow: hidden;
}

.temp_2 .day-names {
  margin-top: 0px;
  line-height: 1.8;
  margin-left: 40px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  height: 49px;
}

.temp_2 .day-number {
  float: left;
  font-size: 20pt;
  margin-top: 4px;
}

/* ############# Template 3 ############# */
.temp_3 .page-wrapper {
  /* background-color: rgb(255, 255, 255);
  flex-direction: column; */
  position: relative;
  padding-right: 0;
}

.temp_3 .month-title {
  position: absolute;
  bottom: 30px;
  right: 55px;
}

.temp_3 .month-title > h2 {
  font-size: 70pt;
}

.temp_3 .day-tiles {
  margin-top: 40px;
  margin-left: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.temp_3 .day-tiles > div:nth-child(even) {
  background-color: rgb(230, 230, 230);
}

.temp_3 .day-tiles > div:nth-child(odd) {
  background-color: rgb(255, 255, 255);
}

.temp_3 .day-tiles > div:nth-child(odd) .day-number {
  color: rgb(230, 230, 230);
}

.temp_3 .day-block {
  position: relative;
  display: inline-block;
  height: 110px;
  width: 110px;
  border: 2px solid black;
  margin-right: 15px;
  margin-bottom: 15px;
  padding: 7px;
  font-size: 13pt;
  overflow: hidden;
  z-index: 0;
}

.temp_3 .day-names {
  margin-top: 4px;
  line-height: 1.8;
  z-index: 2;
}

.temp_3 .day-number {
  position: absolute;
  text-align: center;
  font-weight: bold;
  right: 0;
  left: 0;
  top: 6px;
  margin: auto;
  font-size: 70pt;
  opacity: 1;
  color: white;
  z-index: -1;
}

/* ############# Template 4 ############# */
.temp_4 {
  color: rgb(54, 54, 54);
}

.temp_4 .page-wrapper {
  position: relative;
}

.temp_4 .month-title > h2 {
  position: absolute;
  bottom: 30px;
  right: 45px;
  font-size: 50pt;
  font-family: "Great Vibes", cursive;
}

.temp_4 .day-tiles {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  margin-right: -20px;
}

.temp_4 .day-block {
  position: relative;
  height: 143px;
  width: 129.5px;
  padding: 3px 7px 0px 7px;
  font-size: 13pt;
  overflow: hidden;
  /* Border logic */
  border-width: 0px;
  border-color: rgb(73, 73, 73);
  border-style: solid;
  border-left-width: 1px;
  border-bottom-width: 1px;
}

.temp_4 .day-block:nth-child(-n + 5) {
  border-top-width: 1px;
}

.temp_4 .day-block:nth-child(5n) {
  border-right-width: 1px;
}

.temp_4 .day-block:last-child {
  border-right-width: 1px;
}

.temp_4 .day-names {
  margin-top: 0.7em;
  line-height: 1.3;
}

.temp_4 .day-number {
  float: right;
  font-size: 20pt;
  font-family: "Great Vibes", cursive;
}
#header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  top: 0;
  left: 0;
  height: 60px;
  margin-bottom: 5px;
  background-color: white;
  box-shadow: 0 0 12px 0px rgb(0 0 0 / 100%);
  padding: 5px 10px;
  box-sizing: border-box;

}

#header > div:first-child {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 0 0 350px;
          flex: 0 0 350px;
  -webkit-flex-direction: column;
          flex-direction: column;
  height: 100%;
  -webkit-flex-basis: 365px;
          flex-basis: 365px;
}

#header > div:nth-child(2) {
  -webkit-flex-grow: 1;
          flex-grow: 1;
} 

#pdf-print-button {
  float: right;
  height: 100%;
  padding: 0 30px;
}

#preview-controls select {
  font-size: 11pt;
  height: 22px;
  background-color: rgb(231 231 231);
  border-radius: 5px;
  border: none;
  font-size: 15px;
  height: 25px;
  border: 1px solid grey;
}

#preview-controls select:hover {
  background-color: rgb(245, 245, 245);
}

#preview-controls select:not(:nth-of-type(1)) {
  margin-left: 5px;
}


#preview-controls {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: stretch;
          align-items: stretch;
  height: 100%;
}

#preview-controls > div {
  padding: 4px 20px 0px 20px;
  margin-bottom: -10px;
  border-left: 1.5px solid rgb(219, 219, 219);
  line-height: 1.2;
  height: 90%;
}

.option-title {
  font-size: 11pt;
  width: 80px;
}

@media (max-width: 1260px) {
  #preview-controls select {
    font-size: 11pt;
    height: 22px;
    max-width: 110px;
  }

  .option-title {
    display: none;
  }

  #preview-controls select:not(:nth-of-type(1)) {
    margin-left: 0px;
  }

  #preview-controls select:nth-of-type(1) {
      margin-right: 5px;
}
}

@media (max-width: 1260px) {
  #preview-controls > div {
    padding: 4px 10px 0px 10px;
}
}
#import-box {
  text-align: center;
  margin-bottom: 10px;
}

input[type="file"] {
  display: none;
}

#upload-fb-button {
  float: left;
  width: 335px;
  height: 100%;
  line-height: 3.5;
}

#upload-button {
  padding: 14px 40px;
}

#upload-button * {
  cursor: pointer;
}

.fa-custom {
  font-size: 25px;
  display: inline-block;
}

#facebook-upload-popup {
  /* max-width: 500px; */
  min-width: 200px;
  border-radius: 5px;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 100;
  background-color: white;
  padding: 15px 30px 30px 30px;
  box-shadow: 0px 0px 19px 5px rgba(0, 0, 0, 0.69);
}

.popup-wrapper {
  display: none;
}

.popup-background {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}

.close-popup {
  border-radius: 15px;
  float: right;
  line-height: 25px;
  width: 25px;
  margin-right: -25px;
  margin-top: -8px;
  box-shadow: none;
  height: 25px;
  transition: 0.1s;    
  text-align: center;
}

.close-popup:hover {
  box-shadow: 0px 0px 4px 1px rgb(0 0 0 / 69%);
  cursor: pointer;
  transition: 0.1s;
}

#upload-slide-wrapper {
    position: relative;
}

.upload-slide {
    display: none;
    width: 750px;
    padding: 20px 60px;
    height: 250px;
    margin-bottom: 50px;
    border-top: 1px solid rgb(230,230,230);
    border-bottom: 1px solid rgb(230,230,230);
    -webkit-align-items: center;
            align-items: center;
}

.upload-slide p {
    font-size: 11pt;
    line-height: 1.2;
    text-align: left;
}

#upload-next-btn {
    position: absolute;
    right: 0px;
    top: 40%;
}

#upload-prev-btn {
    position: absolute;
    left: 0px;
    top: 40%;
}

.upload-slide img {
    height: 100%;
    margin-left: 20px;
    border: 1px solid rgb(220,220,220);
}



.slide-button2 {
    padding: 10px 17px;
    border-radius: 4px;
    background-color: rgb(138 138 138);
    opacity: 0.3;
    transition: 0.5s;
    font-size: 20pt;
}

#upload-slide-wrapper:hover .slide-button2 {
    opacity: 0.5;
    transition: 0.5s;   
}


#upload-progress-indicators {
      position: absolute;
      bottom: -25px;
      right: 0;
      left: 0;
      display: block;
}

  #upload-progress-indicators > div {
      height: 8px;
      width: 8px;
      margin: auto 5px 5px 5px;
      border-radius: 10px;
      border: 1px solid black;
      display: inline-block;
  }

  @media (max-width: 1060px) {
    .upload-slide img {
        width: 100%;
        max-width: 500px;
        height: auto;
        margin-left: 0px;
    }
    .upload-slide {
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        width: 450px;
        height: 420px;
    }
  }


#sure-buttons-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    grid-gap: 10px;
    gap: 10px;
}

/* This print.css file is used to create a pdf of the calender that is created in the tool. This file should only be imported in the pdfendpoint file */

body {
  -webkit-print-color-adjust: exact;
}

#pdf-endpoint * {
  visibility: hidden;
}
#pdf-endpoint #section-to-print,
#pdf-endpoint #section-to-print * {
  visibility: visible;
}
#pdf-endpoint #section-to-print {
  position: absolute;
  left: 0;
  top: 0;
  padding-bottom: 0px !important;
}
#pdf-endpoint .page-wrapper {
  display: block !important;
}

/* Getting rid of footer and header */
@page {
  margin: 0;
}

#pdf-endpoint .page-wrapper {
  page-break-after: always;
}

/* Getting rid of extra page in print */

#pdf-endpoint #section-to-print > div:last-child {
  height: 296mm !important;
}

/* Fixing relative-absolute problem */
#pdf-endpoint #bd-preview-wrapper {
  position: static !important;
  position: initial !important;
}

/* Fixing other weird header problem */
#pdf-endpoint #header {
  display: none !important;
}

/* Cleaning up */

#pdf-endpoint .page-wrapper {
  box-shadow: none !important;
}

#pdf-endpoint #calender-preview {
  -webkit-transform: none !important;
          transform: none !important;
  position: unset !important;
}





