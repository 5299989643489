#bdlist-UI {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  /* height: 100%; */
  overflow: hidden;
  margin-bottom: 10px;
  background-color: white;
  padding: 15px;
  border-radius: 7px;
}

#bd-list-container {
  font-size: 13px;
  overflow-y: auto;
  flex-grow: 1;
  margin-left: -8px;
}

#bd-list-container > .list-item-birthday {
  padding: 3px 0 2px 0;
  border-bottom: 1px solid #f1f1f1;
}

#no-birthdays {
  padding: 15px;
  text-align: center;
}

.list-item-birthday {
  position: relative;
}

.list-item-birthday input:first-child {
  margin: 0px 4px 0px 6px;
  vertical-align: middle;
  display: inline-block;
}

#side-menu .list-item-birthday input:nth-child(2) {
  padding: 0px 3px;
  font-size: 13px;
  width: 130px;
}

.list-date {
  display: inline-block;
}

.list-remove-button {
  background-color: rgb(217 217 217);
  position: absolute;
  top: 0px;
  margin: 5px 5px auto auto;
  right: 0;
  padding: 2px 4px 1px 4px;
  color: white;
  font-weight: bold;
  border-radius: 3px;
}

.list-remove-button:hover {
  background-color: rgb(255, 215, 215);
}

#bdlist-option-button-wrapper {
  display: flex;
  flex-direction: row;
  align-content: stretch;
  justify-content: space-around;
  flex-wrap: nowrap;
  gap: 5px;
}

#bdlist-option-button-wrapper > * {
  flex-grow: 1;
  margin: auto;
}

.bdlist-option-button {
  border-radius: 3px;
}

#search-wrapper {
  position: relative;
  margin: 10px 0;
}

#side-menu input,
#side-menu select {
  background-color: rgb(231 231 231);
  border-radius: 5px;
  border: none;
  font-size: 15px;
  height: 25px;
  border: 1px solid grey;
}

#side-menu input[type="text"] {
  padding: 0px 10px;
}

#side-menu input:hover,
#side-menu select:hover {
  background-color: rgb(245, 245, 245);
}

#search-box {
  width: 93.5%;
}

#x-icon {
  position: absolute;
  right: 8px;
  top: 1px;
  margin-left: -15px;
  opacity: 0.5;
  font-weight: bold;
}

#x-icon:hover {
  opacity: 0.8;
  cursor: pointer;
}

.fa-wrapper {
  display: inline-block;
  width: 23px;
}

.facebook-icon {
  height: 1.5em;
  width: auto !important;
  vertical-align: middle;
  color: #457ad5;
  cursor: pointer;
}
